@import "/src/styles/theme.scss";
.wallet {
  @include phone {
    padding-right: 30px;
    margin-right: -12px;
  }
  height: 40px;
  padding: 4px;
  padding-right: 14px;
  font-size: 17px;
  background: var(--BG-Secondary, #1a1c20);
  border-radius: 8px;
  svg {
    width: 24px;
    height: 24px;
  }
}
