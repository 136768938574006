@import "/src/styles/theme.scss";

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 13px 22px;
  font-family: "SF Pro Rounded";
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  text-align: center;
  white-space: nowrap;
  background: transparent;
  border: none;
  transition: all .3s ease-in-out;
  cursor: pointer;

  gap: 4px;

  &:hover {
    opacity: .7;
  }
}

.fuild {
  flex: 1;
  width: 100%;
}
.lg {
  @include phone {
    padding: 14px 18px;
  }
  min-width: 32px;
  padding: 14px 30px;
  font-size: 20px;
  font-weight: 800;
  border-radius: 16px;
}
.loading {
  width: 18px !important;
  height: 18px !important;
}
.md {
  min-width: 32px;
  // @include phone {
  //   padding: 12px;
  //   font-size: 13px;
  // }
  padding: 14px;
  font-size: 15px;
  border-radius: 12px;
  .loading {
    width: 18px  !important;
    height: 18px  !important;
  }
}

.sm {
  min-width: 32px;
  padding: 6px;
  font-size: 15px;
  line-height: 12px;
  border-radius: 12px;
}

.filled {
  &-secondary {
    color: var(--Label-White-90, rgba(255, 255, 255, .90));
    background: #23262a;
  }

  &-primary {
    color: #111315;
    background: #fbac43;
    .loading {
      svg {
        color: #111315 !important;
      }
    }
  }

  &-warning {
    color: #ff3c61;
    background: rgba(255, 255, 255, .1);
  }

  &-danger {
    color: var(--Label-White-90, rgba(255, 255, 255, .90));
    background: #ff3c61;
  }
}

.texted {
  &-secondary {
    color: var(--Label-White-90, rgba(255, 255, 255, .90));
  }

  &-primary {
    color: #fbac43;
  }

  &-warning {
    color: #ff3c61;
  }

  &-danger {
    color: #ff3c61;
  }
}

.outlined {
  &-secondary {
    color: var(--Label-White-90, rgba(255, 255, 255, .90));
    border: 2px solid rgba(255, 255, 255, .05);
  }

  &-primary {
    color: var(--Label-White-90, rgba(255, 255, 255, .90));
    border: 2px solid #fbac43;
  }

  &-warning {
    color: #ff3c61;
    border: 2px solid #ff3c61;
  }

  &-danger {
    color: #ff3c61;
    border: 2px solid #ff3c61;
  }
}

.disabled {
  cursor: not-allowed;
  opacity: .5 !important;
  user-select: none;
  &:hover {
    opacity: .5 !important;
  }
}
