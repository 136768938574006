.container {
  display: flex;
  flex-direction: column;
  padding: 12px;
  margin-top: 18px;
  font-family: SF Pro Rounded;
  font-size: 15px;
  font-style: normal;
  font-weight: 800;
  background: linear-gradient(0deg, rgba(0, 0, 0, .35) 0%, rgba(0, 0, 0, .35) 100%), url(/assets/img/subscription-banner.jpg), lightgray 50% / cover no-repeat;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 12px;

  backdrop-filter: blur(10px);
  gap: 8px;
  &.inline {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    width: auto;
    padding: 6px 12px;
    .button {
      padding: 6px 12px;
    }
  }
}
.title {
  display: flex;
  justify-content: center;
  align-items: center;

  gap: 6px;
}
.subtitle {
  padding-top: 6px;
  color: rgba(255, 255, 255, .7);
  font-weight: 400;
  text-align: center;
}
.text {
  display: flex;
  font-weight: 800;
  line-height: 17.9px;
  white-space: nowrap;
  opacity: .6;
}
.icon {
  display: flex;
  svg {
    width: 23px;
    height: 23px;

    fill: #ffba2c;
  }
}
.grade {
  display: flex;
  white-space: nowrap;
}
.button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  font-size: 15px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  background-color: rgba(0, 0, 0, .70) ;
  border-radius: 8px;
}
.editButton {
  padding: 8px 16px;
  color: rgba(255, 255, 255, .5) !important;
  border-radius: 8px;
  &:hover {
    color: white !important;
  }
}

.link {
  display: flex;
  flex-direction: column;
}
