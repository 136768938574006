.App {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  font-family: "SF Pro Rounded", "Inter", "Roboto", "Helvetica", "Arial", sans-serif;
  text-align: center;
  background-color: var(--romantic-bg);
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 0s 600000s, color 0s 600000s !important;
}
input {
  color-scheme: dark;
}
.page {
  width: 100%;
}
.page-enter {
  opacity: 0;
}

.page-enter-active {
  transition: opacity 300ms, transform 300ms;
  opacity: 1;
}

.page-exit {
  opacity: 1;
}

.page-exit-active {
  transition: opacity 300ms, transform 300ms;
  opacity: 0;
}


.page-content-enter {
  opacity: 0;
}

.page-content-enter-active {
  transition: opacity 300ms, transform 300ms;
  opacity: 1;
}

.page-content-exit {
  opacity: 1;
}

.page-content-exit-active {
  transition: opacity 300ms, transform 300ms;
  opacity: 0;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  text-align: center;
  background: #fff;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;

  object-fit: cover;
}
