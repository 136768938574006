@import "/src/styles/theme.scss";

.modal-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  gap: 16px;
}

.modal-description {
  color: var(--Tints-White-50, rgba(255, 255, 255, .50));
  font-size: 17px;
  font-weight: 600;
  text-align: center;

  span {
    color: var(--Tints-White-100, #fff);
    font-size: 17px;
    font-weight: 800;
  }
}
.button {
  color: var(--Label-White-90, rgba(255, 255, 255, .90));
  font-size: 17px;
  font-weight: 600;
  text-align: center;
}
