@import "/src/styles//theme.scss";
.container {
  @include phone {
    padding: 0;

    gap: 12px;
  }
  display: flex;
  align-items: center;
  padding: 6px 12px 6px 8px;
  border-radius: 12px;
  cursor: pointer;

  gap: 8px;
}
.card {
  @include phone {
    gap: 0;
  }
  display: flex;
  align-items: center;

  gap: 11px;
}
.avatar-container {
  position: relative;
}
.avatar {
  overflow: hidden;
  width: 40px;
  min-width: 40px;
  height: 40px;
  background: #191c20;
  border-radius: 8px;
}
.premium {
  position: absolute;
  right: -2px;
  bottom: -5px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 4px;
  color: var(--Label-Black-90, rgba(0, 0, 0, .90));
  font-size: 8px;
  font-weight: 800;
  text-transform: uppercase;
  background: linear-gradient(180deg, #ffba2c 0%, #ff8a00 100%);
  border-radius: 4px;

  gap: 4px;
}
.avatarIcon {
  padding: 6px;
}

.avatarImage {
  width: 100%;
  height: 100%;

  object-fit: cover;
}

.displayName {
  @include phone {
    display: none;
  }
  overflow: hidden;
  max-width: 200px;
  color: #fff;
  font-family: "SF Pro Rounded";
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: .03em;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.button {
  @include phone {
    padding: 7px 11px;
    font-size: 13px;
    border-radius: 8px;
  }
  padding: 9px 16px;
}
