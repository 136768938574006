@import "/src/styles//theme.scss";

.container {
  @include phone {
    gap: 5px;
  }
  display: flex;
  align-items: center;
  color: var(--main-color);
  text-decoration: none;
  cursor: pointer;

  gap: 18px;
}

.image {
  @include phone {
    width: 20px;
    height: 20px;
    margin-top: 0;
    margin-left: 5px;
  }
  width: 35px;
  height: 35px;
  margin-top: 5px;
  background-image: url(./images/logo.png);
  background-repeat: no-repeat;
  background-size: contain;
}

.label {
  margin-top: 2px;
  color: white;
  font-family: "SF Pro Rounded", "Inter", "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 28px;
  font-weight: 800;
  line-height: 24px;
}

.colorPrimary {
  color: var(--romantic-primary-color);
}
