.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px;
  background: rgba(255, 255, 255, .05);
  border-radius: 12px;

  gap: 12px;
}
.title {
  color: rgba(255, 255, 255, .90);
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: left;
}
.main {
  display: flex;
  justify-content: start;
  align-items: center;

  gap: 2px;
  svg {
    min-width: 32px;
    min-height: 32px;
  }
}

.social-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 10px;

  a: {
    cursor: pointer;
  }
  svg {
    width: 24px;
    height: 24px;
  }
}
.button-discord {
  background: #5865f2;
}
.button-reddit {
  background: #ff4500;
}


.affilateButton {
  margin-top: 16px;
  background-color: rgba(0, 0, 0, .7) !important;
}
