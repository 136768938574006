.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px;
  background: rgba(255, 255, 255, .05);
  border-radius: 12px;
}
.title {
  color: rgba(255, 255, 255, .90);
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.main {
  display: flex;
  justify-content: center;
  align-items: center;

  gap: 23px;
}
.footer {
  margin-top: 16px;
  color: var(--Label-White-50, rgba(255, 255, 255, .50));
  /* Regular/13 */
  font-family: "SF Pro Rounded";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  a: {
    cursor: pointer;
  }
}
.social-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 10px;

  a: {
    cursor: pointer;
  }
  svg {
    width: 24px;
    height: 24px;
  }
}
.button-discord {
  background: #5865f2;
}
.button-reddit {
  background: #ff4500;
}


.affilateButton {
  margin-top: 16px;
  background-color: rgba(0, 0, 0, .7) !important;
}


.profile {
  align-items: start;
  width: 100%;
  .main {
    justify-content: start;
    width: 100%;
  }
  .title {
    flex: 1;
    text-align: left;
  }
}
