@import "/src/styles/mixins/index.scss";


.container {
  @include phone {
    justify-content: start;
    padding: 22px;
  }
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  transition: opacity .3s ease-in-out;
  opacity: 1;
}
.loading {
  opacity: 0;
}
.phoneFullscreen {
  @include mobile {
    justify-content: start;
    padding: 0;
    .content {
      width: 100%;
      min-width: 100%;
      max-width: 100%;
      height: 100%;
      margin: 0;
      border-radius: 0;
    }
  }
}
.eclipse {
  z-index: -1;
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "SF Pro Rounded";
  font-size: 28px;
  font-style: normal;
  font-weight: 800;
  line-height: 33px;
  text-align: center;
}

.content {
  @include mobile {
    width: 100%;
    padding: 22px 24px;
  }
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 58px 64px;
  margin: auto;
  color: #fff;
  font-family: "SF Pro Rounded";
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  background: #111315;
  border-radius: 24px;

  gap: 32px;
}

.close {
  position: absolute;
}

.actions {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  gap: 18px;
}

.closeButton {
  position: absolute;
  top: 22px;
  right: 22px;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 26px;
  border: 50%;
  cursor: pointer;

  &:hover {
    svg {
      opacity: .7;
    }
  }
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 125px;
  height: 125px;
}


.phoneDrawer {
  @include phone {
    justify-content: flex-end;
    overflow: hidden;
    padding: 0 !important;

    .closeButton {
      display: none;
    }
    .eclipse {
      transition: all ease-in-out .3s;
      opacity: 0;
    }
    .content {
      position: relative;
      overflow: hidden;
      padding-top: 42px;
      padding-bottom: 24px;
      margin: 0;
      background: #1a1c20;
      border-radius: 24px 24px 0 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      transform: translateY(100%);
      transition: all ease-in-out .3s;

      gap: 20px;

      &::before {
        content: "";
        position: absolute;
        top: 12px;
        left: calc(50% - 30px);
        z-index: 1;
        width: 60px;
        height: 6px;
        background: #23262a;
        border-radius: 30px;
      }
    }

    &.phoneDrawerOpen {
      .content {
        transform: translateY(0);
      }

      .eclipse {
        opacity: 1;
      }
    }
  }
}
