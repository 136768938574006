.container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1001;
  width: 100%;
  height: 100%;
  background-color: black;
}

.vidio-container {
  width: 100%;
  height: calc(100% - 155px);
}
.vidio {
  background-color: black;
}
.slider {
  height: calc(100vh - 150px);
}
.slide {
  position: relative;
  flex-direction: column;
  background: black !important;
}
.text {
  width: 100%;
  height: 59px;
  padding: 0 14px;
  pointer-events: none;
  user-select: none;
  img {
    width: 100%;
    height: 100%;

    object-fit: contain !important;
  }
}

.actions {
  padding: 12px 22px;
}
.next {
  padding: 8px 12px;
  font-size: 16px;
  font-size: 24px;
  background-color: transparent;
  user-select: none;
}

.thumbs {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 22px;

  gap: 8px;
  &-circle {
    width: 11px;
    height: 11px;
    background-color: rgba($color: #fff, $alpha: .5);
    border-radius: 50%;
    transition: all .3s ease-in-out;
    &-active {
      background-color: rgba($color: #fff, $alpha: .9);
    }
  }
}
