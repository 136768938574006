.container {
  display: flex;
  flex-direction: column;
  padding: 12px;
  font-size: 15px;
  font-style: normal;
  font-weight: 800;
  background-color: rgba(255, 186, 44, .05);
  border: 2px solid  #ffba2c;
  border-radius: 12px;
  cursor: pointer;
  user-select: none;

  backdrop-filter: blur(10px);
  gap: 8px;
  &.inline {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    width: auto;
    padding: 6px 12px;
    .button {
      padding: 6px 12px;
    }
  }
}
.title {
  display: flex;
  align-items: center;

  gap: 6px;
}
.text {
  display: flex;
  font-weight: 800;
  line-height: 17.9px;
  text-align: left ;
  opacity: .6;
}
.icon {
  display: flex;
}
.grade {
  display: flex;
  white-space: nowrap;
}
.button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  font-size: 15px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  background: var(--tints-accent-blue, #06f);
  border-radius: 8px;
}
.editButton {
  padding: 8px 16px;
  color: rgba(255, 255, 255, .5) !important;
  border-radius: 8px;
  &:hover {
    color: white !important;
  }
}

.link {
  display: flex;
  flex-direction: column;
}
