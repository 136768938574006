@import "/src/styles/theme.scss";


.container {
  display: flex;
  flex-direction: column;
  padding: 40px 56px;
  background: #1a1c20;
  border-radius: 24px;

  gap: 0;
}


.description {
  max-width: 320px;
  margin-bottom: 12px;
  color: rgba(255, 255, 255, .90);
  font-size: 15px;
  font-weight: 600;
  text-align: center;
}

.title {
  @include mobile {
    font-size: 28px;
  }
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  color: #fff;
  font-size: 34px;
  font-weight: 900;
  text-align: center;

  gap: 6px;

  &-hearts {
    color: #ffba2c;
  }
}

.reward {
  &__list {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;

    gap: 12px;
  }
  &__item {
    position: relative;
    width: 28px;
    height: 28px;
    background: rgba(255, 186, 44, .20);
    box-shadow: 0 0 0 rgba(255, 185, 44, 0);
    border: 3px solid #ffba2c;
    border-radius: 18px;
    transition: all 1s ease-in-out;
    &__available {
      animation: light 1s ease-in-out infinite alternate;
    }
    &__pending {
      animation: light2 1s ease-in-out infinite alternate;
    }
    &__complited {
      position: absolute;
      top: -4px;
      left: -4px;
      animation: fadeIn .3s ease-in-out both;
      opacity: 0;
      img {
        width: 31px;
        height: 31px;

        object-fit: cover;
      }
    }
  }
}

.actions {
  display: flex;

  gap: 8px;
}
.submit {
  background-color: transparent !important;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes light {
  from {
    box-shadow: 0 0 15px 5px rgba(255, 185, 44, .0), inset 0 0 5px  rgba(255, 185, 44, .0);
  }
  to {
    box-shadow: 0 0 25px 0 rgba(255, 185, 44, .5), inset 0 0 10px  rgba(255, 185, 44, .5);
  }
}
@keyframes light2 {
  from {
    box-shadow: 0 0 25px 5px rgba(255, 185, 44, .5), inset 0 0 5px  rgba(255, 185, 44, .3);
  }
  to {
    box-shadow: 0 0 35px 0 rgba(255, 185, 44, .8), inset 0 0 10px  rgba(255, 185, 44, .6);
  }
}

.premium {
  // width: 452px;
  background-image: url("/assets/new-price/reward-bg.png");
  background-repeat: no-repeat;
  background-size: cover;


  .title-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    margin-bottom: 8px;
    img {
      width: 62px;
      height: 62px;

      object-fit: contain;
    }
  }
  .subtitle {
    color: #ffba2c;
    font-size: 15px;
    font-weight: 800;
    text-align: center;
  }
  .title-text {
    font-size: 28px;
    font-weight: 900;
  }
}

.list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 32px;

  gap: 12px;
  &-item {
    display: flex;
    align-items: center;
    &-label {
      color: rgba(255, 255, 255, .90);
      font-size: 15px;
      font-weight: 600;
      line-height: normal;
      text-align: center;
    }
    &-icon {
      margin-right: 8px;
    }
  }
}
