@import "/src/styles/theme.scss";

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  width: 100%;
  height: 100%;
  transition: all .3s ease-in-out;
  opacity: 0;
}

.loaded {
  &.main {
    opacity: 1;
  }
}

.loader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  transition: all .3s ease-in-out;
  opacity: 0;
  pointer-events: none;
}
.loader__loading {
  opacity: 1;
  pointer-events: inherit;
}
.logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 12px;
  animation: fadeIn .6s ease-in-out;

  gap: 6px;
  &__icon {
    box-shadow: 0 1.39px 0 0 #fff inset, 0 -8.338px 11.117px 0 rgba(0, 0, 0, .36) inset, 0 8.338px 16.675px 0 rgba(255, 255, 255, .90) inset;
    filter: drop-shadow(0px 8.338px 16.675px rgba(0, 0, 0, .25));
  }
  svg {
    @include mobile {
      width: 67px;
      height: 67px ;
    }
    width: 58px;
    height: 58px;
  }
  &__title {
    @include mobile {
      font-size: 34px;
    }
    color: var(--Label-White-90, rgba(255, 255, 255, .90));
    font-size: 24px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    text-align: center;
    animation: fadeIn .9s ease-in-out;
  }
  &__letter {
    animation: flickering .5s  ease-in-out .5s  infinite alternate;
  }
  &__primary {
    @include mobile {
      font-size: 34px;
    }
    color: #ff8a00;
    font-size: 24px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    // background: linear-gradient(180deg, #ffba2c 0%, #ff8a00 100%);
    //         background-clip: text;

    // -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;
  }
}
.scrollbar {
  @include phone {
    max-height: calc(100% - 126px);
    padding-bottom: 50px;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: scroll;
  width: 100%;
  max-height: calc(100% - 88px);
  padding-bottom: 44px;
}
.content {
  @include phone {
    padding: 0 16px;

    gap: 16px;
  }
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  max-width: 1160px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes flickering {
  from {
    opacity: .7;
  }
  to {
    opacity: 1;
  }
}
