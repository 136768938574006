@mixin phone {
  @media (max-width: 1100px) {
    @content;
  }
}
@mixin mobile {
  @media (max-width: 500px) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: 1100px) {
    @content;
  }
}
