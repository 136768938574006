@import "/src/styles/theme.scss";

.container {
  @include phone {
    flex-direction: column;
    padding: 6px;
    padding-top: 24px;
    padding-bottom: 60px;
    padding-left: 6px;

    gap: 16px;
  }
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: start;
  align-items: center;
  overflow-y: auto;
  width: 100%;
  max-height: 100vh;
  padding: 24px;

  gap: 60px;
}
.title {
  display: flex;
  justify-content: start;
  width: 100%;
  h3 {
    @include phone {
      font-size: 24px;
    }
  }
}
.back-button {
  margin-right: 8px;
}
.main {
  @include phone {
    width: 100%;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 1160px;

  gap: 24px;
}

.back-button-label {
  padding-right: 12px;
}
.list {
  @include phone {
    flex-direction: column;
    flex-wrap: nowrap;
  }
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  margin: 24px;

  gap: 12px;
}
.item {
  @include phone {
    width: 100%;
  }
  width: calc(33% - 12px);
  &-button {
    text-decoration: underline;
  }
}
