@import "/src/styles/theme.scss";

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px;
  font-size: 15px;
  font-style: normal;
  font-weight: 800;
  background: radial-gradient(577.57% 197.57% at -4.08% 148.33%, rgba(255, 186, 44, .20) 19.9%, rgba(255, 255, 255, .20) 63.47%, rgba(255, 138, 0, .20) 88.39%);
  border: 2px solid  #ffba2c;
  border-radius: 16px;
  user-select: none;
  // backdrop-filter: blur(10px);

  gap: 8px;
  &.inline {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    width: auto;
    padding: 6px 12px;
    .button {
      padding: 6px 12px;
    }
  }
}
.title {
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(255, 255, 255, .90);
  font-size: 28px;
  font-weight: 800;
  line-height: normal;

  gap: 6px;
}
.subtitle {
  padding-top: 6px;
  color: rgba(255, 255, 255, .7);
  font-weight: 400;
  text-align: center;
}
.text {
  white-space: nowrap;
}
.icon {
  display: flex;
  svg {
    width: 23px;
    height: 23px;

    fill: #ffba2c;
  }
}
.grade {
  display: flex;
  white-space: nowrap;
}
.button {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  padding: 12px 16px;
  color: rgba(0, 0, 0, .90) !important;
  font-size: 15px;
  font-weight: 900 !important;
  text-align: center;
  background: radial-gradient(577.57% 197.57% at -4.08% 148.33%, #ffba2c 19.9%, #fff 63.47%, #ff8a00 88.39%);
  border-radius: 8px;
}
.editButton {
  padding: 8px 16px;
  color: rgba(255, 255, 255, .5) !important;
  border-radius: 8px;
  &:hover {
    color: white !important;
  }
}

.link {
  display: flex;
  flex-direction: column;
}
.options {
  @include phone {
    gap: 10px;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;

  gap: 5px;
  &-item {
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    opacity: .9;

    gap: 8px;
    &-label {
      text-align: left;
      white-space: nowrap;
    }
    &-icon {
      width: 28px;
      height: 28px;
      font-size: 23px;
    }
  }
}
.lips {
  width: 28px;
  height: 28px;
  background-image: url("/assets/new-price/sub/lips.png");
}

.navigation-mode {
  &.container {
    gap: 12px;
  };
  .title {
    font-size: 20px;
  }
  .options {
    @include phone {
      gap: 10px;
    }
    display: flex;
    flex-direction: column;
    justify-content: center;

    gap: 12px;
    &-item {
      font-size: 13px;

      &-icon {
        width: 16px;
        height: 16px;
        font-size: 14px;
      }
    }
  }
  .lips {
    width: 16px;
    height: 16px;
  }
}

.upgradesex {
  &.container {
    background: linear-gradient(92deg, rgba(164, 187, 255, .10) -10.11%, rgba(255, 100, 231, .10) 102.28%);
    border: 2px solid  #a4bbff;
  }
  .text {
    color: rgba(255, 255, 255, .90);
    font-size: 20px;
    font-weight: 900;
    span {
      font-weight: 900;
      background: linear-gradient(92deg, #a4bbff -10.11%, #ff64e7 102.28%);
              background-clip: text;

      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  .button {
    background: linear-gradient(92deg, #a4bbff -10.11%, #ff64e7 102.28%);
  }
}
.full-subscription {
  &.container {
    background: linear-gradient(92deg, rgba(164, 187, 255, .10) -10.11%, rgba(255, 100, 231, .10) 102.28%);
    border: 2px solid  #a4bbff;
  }
  .text {
    color: rgba(255, 255, 255, .90);
    font-size: 20px;
    font-weight: 900;
    span {
      font-weight: 900;
      background: linear-gradient(92deg, #a4bbff -10.11%, #ff64e7 102.28%);
              background-clip: text;

      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  .button {
    background: linear-gradient(92deg, #a4bbff -10.11%, #ff64e7 102.28%);
  }
}
.chat-mode {
  &.container {
    flex-direction: row;
    padding: 6px 8px;
    padding-left: 12px;

    gap: 12px;
  }
  .text {
    font-size: 16px;
  }
  .options {
    display: none;
  }
  .button {
    padding: 8px 12px;
    font-size: 14px;
  }
}

.hearts-mode {
  &.container {
    flex-direction: row;
    justify-content: space-between;
    flex: 1;
    width: 100%;
    padding: 16px;
    background: linear-gradient(92deg, rgba(164, 187, 255, .20) -10.11%, rgba(255, 100, 231, .20) 102.28%);
    border: 2px solid rgb(215, 125, 237);

    gap: 12px;
  }
  .text {
    font-size: 17px;
    font-weight: 900;
    span {
      background: var(--Gradients-Blue-Pink-Light, linear-gradient(92deg, #a4bbff -10.11%, #ff64e7 102.28%));
              background-clip: text;

      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  .options {
    display: none;
  }
  .button {
    padding: 8px 28px !important;
    color: rgba(255, 255, 255, .90) !important;
    font-size: 17px !important;
    font-weight: 900;
    text-align: center;
    background: var(--Gradients-Blue-Pink-Light, linear-gradient(92deg, #a4bbff -10.11%, #ff64e7 102.28%));
    border-radius: 12px;
  }
  .hearts-title {
    display: flex;
    flex-direction: column;

    gap: 2px;
  }
  .hearts-description {
    color: rgba(255, 255, 255, .60);
    font-size: 13px;
    font-weight: 400;
    text-align: left;
  }
}
